import React from "react";
import Skills from "./Skills";
import BgImg from "../assets/bg.jpg";
import clientPhoto from "../assets/chaim.jpg";

const HomePage = ({ btnClasses, email, whatsApp }) => {
  return (
    <>
      <div className="z-10 min-h-screen relative w-full flex flex-col justify-center items-center p-14 gap-8 bg-cover bg-center sm:px-28 px-1">
        <img
          className="z-0 absolute opacity-50 inset-0 h-full w-full object-cover"
          src={BgImg}
          alt="background"
        />
        <div className="z-10 relative flex flex-col items-center">
          <img
            className="z-10 rounded-full border-2 border-white shadow-lg mb-4 w-32 h-32 sm:w-52 sm:h-52 object-cover"
            src={clientPhoto}
            alt="The CEO"
          />
          <h2 className="sm:text-4xl text-3xl">Chaim Perman</h2>
        </div>
        <h1 className="z-10 text-center sm:text-6xl text-4xl">
          Reliable Handyman Services for Your Home Repairs and Projects!
        </h1>
        <p className="z-10 text-center text-xl">
          Are you looking for a skilled, dependable, and friendly handyman to
          help with your home improvement projects, repairs, or installations?
          Look no further!
        </p>
        <div className="z-10 flex gap-3">
          <a href={whatsApp} className={btnClasses}>
            WhatsApp
          </a>
          <a href={`mailto:${email}`} className={btnClasses}>
            Email
          </a>
        </div>
      </div>
      <Skills />
    </>
  );
};

export default HomePage;
