import React, { useState } from "react";
import Modal from "react-modal";
import images from "./importImages";

// Set the app element for accessibility
Modal.setAppElement("#root");

export default function PhotoLibrary() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  return (
    <div
      className="container mx-auto p-4"
      onClick={modalIsOpen ? closeModal : null}
    >
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Number ${index + 1}`}
              className="w-full h-full object-cover" // cursor-pointer
              // onClick={() => openModal(image)}
            />
          </div>
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="absolute inset-0 flex items-center justify-center"
        overlayClassName="Overlay"
      >
        <div className="relative">
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 m-4 text-white text-2xl font-bold"
          >
            &times;
          </button>
          {currentImage && (
            <img
              src={currentImage}
              alt="Large View"
              className="w-auto h-auto max-w-full max-h-full"
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
