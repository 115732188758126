import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { FaWhatsapp } from "react-icons/fa";

export default function Contact({email, phoneNum, whatsApp, btnClasses}) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="max-w-2xl w-full bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold mb-6 text-center">Contact Us</h1>
        <form className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input type="text" id="name" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Your Name" />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input type="email" id="email" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Your Email" />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
            <input type="phone" id="phone" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Your Phone" />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
            <textarea id="message" className="min-h-24 mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" rows="4" placeholder="Your Message"></textarea>
          </div>
          <div className="flex justify-center">
            <button onClick={()=> alert('This form is not working at the moment please email or call')} type="submit" className={btnClasses}>Send Message</button>
          </div>
        </form>
        <div className="mt-6 text-center">
          <p className="text-gray-500">Or connect with us on:</p>
          <div className="flex justify-center space-x-4 mt-4">
            <a href={`mailto:${email}`} className="text-gray-600 hover:text-blue-600 flex items-center space-x-2">
              <EnvelopeIcon className="h-6 w-6" />
              <span>Email</span>
            </a>
            <a href={whatsApp} className="text-gray-600 hover:text-blue-600 flex items-center space-x-2">
              <FaWhatsapp className='h-6 w-6' />
              <span>WhatsApp</span>
            </a>
            <a href={`tel:${phoneNum}`} className="text-gray-600 hover:text-blue-600 flex items-center space-x-2">
              <PhoneIcon className="h-6 w-6" />
              <span>Call</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
