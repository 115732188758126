import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import contact from "../assets/Chaim-Perman.vcf";
import { FaInstagram } from "react-icons/fa";

const Footer = ({ btnClasses }) => {
  const date = new Date().getFullYear();
  const SocialMedia = [
    {
      icon: <FaInstagram className="h-6 w-6" />,
      name: "Instagram",
      link: "https://www.instagram.com/homerepairswithchaim",
    },
  ];
  return (
    <footer className="bg-white m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex flex-col items-center sm:flex-row sm:items-center sm:justify-between">
          <Link
            to="/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src={logo} className="h-16" alt="Everyday home repairs" />
          </Link>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <Link
                to="/photo-library"
                className="hover:underline me-4 md:me-6"
              >
                Photo Library
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">
                Contact
              </Link>
            </li>
          </ul>
          <a href={contact} className={btnClasses}>
            Save My Contact
          </a>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex flex-row-reverse justify-between">
          <div className="flex gap-1">
            {SocialMedia.map((i) => (
              <a
                href={i.link}
                className="text-gray-600 hover:text-gray-900 dark:hover:gray-400"
              >
                {i.icon}
                <span className="sr-only">{i.name}</span>
              </a>
            ))}
          </div>
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {date}{" "}
            <Link to="/" className="hover:underline">
              Everyday Home Repairs™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
