import React from "react";

const tasks = [
  {
    icon: "•", // "🖼️"
    title: "Hanging Pictures",
    description: "Hang pictures, curtains, shelves, and more.",
  },
  {
    icon: "•", // "🛋️"
    title: "Furniture Assembly",
    description: "Assemble various types of furniture.",
  },
  {
    icon: "•", // "🔧"
    title: "Fixtures Installation",
    description: "Install and replace various fixtures.",
  },
  {
    icon: "•", // "🧹"
    title: "Gutter Cleaning",
    description: "Clean out gutters to ensure proper drainage.",
  },
  {
    icon: "•", // "🎨"
    title: "Drywall Patching & Painting",
    description: "Patch drywall and apply paint.",
  },
  {
    icon: "•", // "🔩"
    title: "Hardware Installation",
    description: "Install and replace hardware.",
  },
  {
    icon: "•", // "🔐"
    title: "Door Lock Installation",
    description: "Install and replace door locks.",
  },
];
export default function Skills() {
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-center sm:text-6xl text-4xl py-12">
        Handyman Services
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tasks.map((item, index) => (
          <div key={index} className="p-4 bg-gray-800 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <div className="mr-4 text-3xl text-slate-300">{item.icon}</div>
              <h3 className="text-xl font-semibold text-slate-300">
                {item.title}
              </h3>
            </div>
            <p className="text-slate-500">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
