import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
// import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar({ phoneNum }) {
  const location = useLocation();
  const navigation = [
    { name: "Dashboard", href: "/", current: location.pathname === "/" },
    {
      name: "Photo library",
      href: "/photo-library",
      current: location.pathname === "/photo-library",
    },
    {
      name: "Contact",
      href: "/contact",
      current: location.pathname === "/contact",
    },
  ];
  return (
    <Disclosure as="nav" className="bg-gray-800 min-h-28">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-28 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block size-8 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden size-8 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex shrink-0 items-center">
              <img
                alt="Everyday home repairs"
                src={Logo}
                className="h-12 w-auto"
              />
            </div>
            <div className="hidden sm:ml-6 sm:block flex self-center">
              <ul className="flex space-x-4">
                {navigation.map((item) => (
                  <li>
                    <Link
                      key={item.name}
                      to={item.href}
                      aria-current={item.current ? "page" : undefined}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "rounded-md px-3 py-2 text-sm font-medium"
                      )}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <a href={`tel:${phoneNum}`}>
              <button
                type="button"
                className="flex p-1 text-gray-400 hover:text-white hidden sm:flex items-center"
              >
                <DevicePhoneMobileIcon aria-hidden="true" className="size-8" />
                Call Now
              </button>
              <DevicePhoneMobileIcon
                aria-hidden="true"
                className="size-8 text-gray-400 hover:text-white sm:hidden"
              />
            </a>
          </div>
        </div>
      </div>
      <DisclosurePanel className="sm:hidden">
        <ul className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <li>
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                aria-current={item.current ? "page" : undefined}
                className={classNames(
                  item.current
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium"
                )}
              >
                {item.name}
              </DisclosureButton>
            </li>
          ))}
        </ul>
      </DisclosurePanel>
    </Disclosure>
  );
}
