import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import Contact from './components/Contact';
import PhotoLibrary from './components/PhotoLibrary';
import { IoLocationOutline } from "react-icons/io5";





function App() {
  const whatsappMessage = encodeURIComponent("Hey, I'm looking for a handyman to do a job for me");
  const whatsApp = `https://wa.me/+13238234245?text=${whatsappMessage}`
  const email = 'thehandymanla@yahoo.com'
  const btnClasses = 'cursor-pointer text-white bg-cyan-950 px-6 py-4 rounded-lg'
  const phoneNum = '+13238234245'
  return (
    <Router> 
      <div className="bg-neutral-600 text-white p-3 sm:text-base text-xs text-center flex items-center justify-center">
        <IoLocationOutline className='w-6 h-6' />
        <p>Our services are currently available only in LA area.</p>
      </div>
        <NavBar phoneNum={phoneNum} />
      <Routes> 
        <Route path="/" element={<HomePage btnClasses={btnClasses} email={email} whatsApp={whatsApp} />} /> 
        <Route path="/contact" element={<Contact email={email} phoneNum={phoneNum} whatsApp={whatsApp} btnClasses={btnClasses} />} /> 
        <Route path="/photo-library" element={<PhotoLibrary />} /> 
        {/* <Route path="*" element={<NotFound />} />  */}
      </Routes> 
      <Footer btnClasses={btnClasses}/>
    </Router> 
  );   
}

export default App;
